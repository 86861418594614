import AnimatorPage from 'components/pages/be-an-animator/AnimatorPage';
import Seo from 'components/Seo';
import { graphql } from 'gatsby';
import * as React from 'react';

const PageEnAnimator = () => (
	<div>
		<Seo
			title='Supplier financing program | FINTEQ'
			keywords='supply chain, financial liquidity, invoice, financing for companies, relationships with suppliers, supplier financing, excess capital, extra profit'
			description={'Meet FINTEQ - bet on your company\'s supply chain excellence, manage liquidity and build a competitive advantage in the industry.'}
			lang='en-US'
			locale='en_US'
		/>

		<AnimatorPage />
	</div>
);

export default PageEnAnimator;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(filter: {language: {eq: $language}}) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
